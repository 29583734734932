import React, { useRef, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, TextField } from '@mui/material';
import theme from 'theme';
import CalendarTodaySharp from '@mui/icons-material/CalendarTodaySharp';
import ChevronLeftSharp from '@mui/icons-material/ChevronLeftSharp';
import ChevronRightSharp from '@mui/icons-material/ChevronRightSharp';
import ExpandMoreSharp from '@mui/icons-material/ExpandMoreSharp';
import CloseSharp from '@mui/icons-material/CloseSharp';
import { EventBusySharp } from '@mui/icons-material';
import styled from '@emotion/styled';

export const BUTTON_VARIANT = 'filled';
export const FORM_VARIANT = 'standard';

export const PopperSlotProps = {
    placement: 'bottom-start',
    sx: { 
      'button.MuiIconButton-root': {
        svg: { color: 'colors.neutral6' },
        '&.Mui-disabled svg': { color: 'colors.neutral4' }
      },
      '.MuiPaper-outlined.MuiPickersPopper-paper': { 
        borderColor: 'level3',
        borderWidth: '1px',
        borderRadius: 0,
      },
      '.MuiDateCalendar-root': {
        height: 'unset'
      },
      '.MuiPickersSlideTransition-root': {
        minHeight: '200px'
      },
      '.MuiButtonBase-root.MuiPickersDay-root, .MuiPickersMonth-monthButton, .MuiPickersYear-yearButton, .MuiMultiSectionDigitalClockSection-item': {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'level3'
        },
        '&.Mui-selected, &.Mui-selected:focus': {
          backgroundColor: 'optimalblue.main',
          color: 'text.inverse',
          '&:hover': {
            backgroundColor: 'optimalblue.secondary',
          }
        },
      },
      '.MuiPickersMonth-monthButton, .MuiPickersYear-yearButton, .MuiMultiSectionDigitalClockSection-item': {
        borderRadius: '4px'
      }
    }
  };

const ODStyledDatePickerTextField = styled(TextField, {
    shouldForwardProp: (prop) => !['extraStyles', 'isErrored', 'variant'].includes(prop),
    name: 'ODDatePickerTextField'
  })(({ theme, extraStyles, isErrored, variant, value }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    input: {
      ...theme.typography,
      fontSize: 12,
      marginLeft: 8,
    },
    '.MuiInputBase-root': {
      flexDirection: variant === BUTTON_VARIANT ? 'row-reverse' : 'row',
      padding: variant === FORM_VARIANT ? '0 4px 0 16px' : 0,
      width: variant === BUTTON_VARIANT ? '140px' : '100%',
      backgroundColor: variant === BUTTON_VARIANT ? (isErrored ? theme.palette.alert.main : theme.palette.level2) : theme.palette.level1,
      color: isErrored ? (variant === FORM_VARIANT ? theme.palette.alert.main : theme.palette.text.inverse) : theme.palette.text.primary,
      '&:before, &:after': {
        display: 'none'
      },
      '.MuiInputBase-input': {
        padding: variant === BUTTON_VARIANT ? '4px' : '4px 0', 
        fontSize: 12,
      },
      '&:hover': {
        backgroundColor: (variant === BUTTON_VARIANT && isErrored) ? theme.palette.alert.secondary : theme.palette.level3,
      },
      ...(variant === FORM_VARIANT && {
        borderRadius: 0,
        borderBottom: '1px solid',
        borderBottomColor: isErrored ? theme.palette.alert.main : theme.palette.level3,
      }),
      ...extraStyles,
    },
    '.MuiIconButton-root': {
      marginRight: '0px',
      borderRadius: 0,
      padding: '2px',
      '&:hover': {
        backgroundColor: (isErrored && variant === BUTTON_VARIANT) ? theme.palette.alert.tertiary : theme.palette.level4,
      },
    },
    '.MuiSvgIcon-root': {
      color: isErrored
        ? (variant === BUTTON_VARIANT ? theme.palette.text.inverse : theme.palette.alert.main)
        : (!value ? theme.palette.text.secondary : theme.palette.text.primary)
    },
  }));

const inputStyle = (isActive) => ({
    padding: '0 6px',
    '& .MuiSvgIcon-root': {
        color: isActive ? theme.palette.black : theme.palette.ODBlue.odBlue7
    },
    color: isActive ? theme.palette.black : theme.palette.ODBlue.odBlue7,
    '& .MuiInputAdornment-root': { marginRight: '6px' },
    '& .MuiInputBase-input': {
        color: isActive ? theme.palette.black : theme.palette.ODBlue.odBlue73
    }
});

const EventBusyAdjusted = () => (
    <EventBusySharp sx={{ transform: 'scale(1.11111, 1.1) translateY(1px)' }} />
  );

  const SmallCalendarIcon = (props) => (
    <CalendarTodaySharp {...props} sx={{ fontSize: 10, marginRight: 1 }} /> 
);


export default function DateTimePickerWrapper({
    selectedValue,
    setSelectedValue,
    limit,
    unfilteredMax,
    unfilteredMin,
    inputRenderingFn,
    isErrored = false,
    variant,
}) {
    const datePickerRef = useRef();
    const [isActive, setIsActive] = useState(false);

    const wrappedInputRenderingFn = () => {
        const inputElement = inputRenderingFn({ isActive, clickHandler: () => setIsActive(true) });
        return <Box ref={datePickerRef}>{inputElement}</Box>;
    };

    return (
        <DateTimePicker
            renderInput={wrappedInputRenderingFn}
            slotProps={{
                desktopPaper: {
                  square: true,
                  variant: 'outlined'
                },
                popper: {
                  ...PopperSlotProps,
                },
                textField: {
                  hiddenLabel: true,
                  size: 'small',
                  variant,
                  label: '',
                  isErrored,
                  clearable: variant === FORM_VARIANT
                }
              }}
            PopperProps={{
                anchorEl: datePickerRef.current,
                id: 'filter',
                placement: 'bottom-start'
            }}
            InputProps={{
                sx: inputStyle(isActive),
                value: selectedValue,
                disabled: true
            }}
            open={isActive}
            onClose={() => {
                setIsActive(false);
            }}
            onOpen={() => {
                setIsActive(true);
            }}
            slots={{
                clearIcon: CloseSharp,
                openPickerIcon: isErrored ? EventBusyAdjusted : SmallCalendarIcon,
                leftArrowIcon: ChevronLeftSharp,
                rightArrowIcon: ChevronRightSharp,
                switchViewIcon: ExpandMoreSharp,
                textField: ODStyledDatePickerTextField
              }}
            onChange={(value) => {
                if (value instanceof Date) {
                    setSelectedValue(value);
                }
            }}
            minDateTime={unfilteredMin}
            maxDateTime={unfilteredMax}
            value={selectedValue ?? limit}
        />
    );
}
