/* eslint-disable eqeqeq */
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
//utils
import {
    getLoadSourceQueryParam,
    getFreightBonusQueryParam,
    initialLoadsKPIFilterObj
} from 'utils/network.util';
import { LOAD_SOURCE, NETWORK_TABLE_TYPE, REPORT_STATUS } from 'constants/network';
import { DRIVER_MODE } from 'constants/drivers';
import {
    updateNetworkView,
    getConfigurationByRunId,
    getKPIbyReportId,
    updateDriverReducer,
    getTableDataByIdAndFilters,
    setLoadFilters,
    getConfigurationActions,
    setConfiguration,
    getFlowData,
    getReportDomiciles,
    initializeSelectedReports,
    settingsClear,
    settingsUpdate,
    setDraftConfigurationActions,
    updateLoadSource,
    getLocationByReportId,
    resetReportDataPreserveContext,
    getReportAppliedBids,
    setAppliedBids,
    getMapboxKey,
    getDriverTypesByReportId,
    getReportsByBucketOrReportId,
    getReportByReportId,
    getInitialRunData
} from 'redux/actions';
import { useParamsProps } from 'types/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useLayoutEffect } from 'react';
import { LEFTNAV_OPTION } from 'constants/settings';
import { LOAD_TYPES } from 'constants/shippers';
import useNetworkViewHook from './useNetworkViewHook';

//TODO leaving comments on purpose until everything is stabilized.

const useNetworkViewEffects = () => {
    const dispatch = useDispatch();
    const { base_run, compare_run, config_id, bucket_id }: useParamsProps = useParams();
    const {
        isListView,
        currTab,
        checked: networkChecked
    } = useSelector((state: RootState) => state.NetworkReducer);
    const { allowEditOnAggregatedDrivers, v2KpIs } = useFlags();
    const { reportDomiciles } = useSelector((state: RootState) => state.DriversReducer);
    const { loadFilters, loadSource, isFreightBonusApplied, reportFilters } = useSelector(
        (state: RootState) => state.ReportsReducers
    );
    const { updateBaseAndCompareReportState, refreshNetworkViewTable } = useNetworkViewHook();

    const { configuration, configurations, configurationActions } = useSelector(
        (state: RootState) => state.ConfigurationReducer
    );
    const { checked } = useSelector((state: RootState) => state.DriversReducer);
    const { aggregationLevel } = useSelector(({ settingsReducer }: RootState) => settingsReducer);
    const { mapBoxKey } = useSelector(
        (state: RootState) => state.ReportKPIReducer
    );

    const updateCurrSimulation = (simId: string | number) => {
        const currSimulation = configurations.find(
            ({ id }: { id: string | number }) => id === simId
        );
        if (currSimulation) dispatch(setConfiguration(currSimulation));
    };

    const useGetFlowDataEffect = (lanes: any, shippers: any, checked: any, load_type: any) => {
        useEffect(() => {
            const { selectedShippers = [], selectedLanes = [] } = networkChecked;
            const originalList =
                currTab === NETWORK_TABLE_TYPE.SHIPPERS
                    ? loadFilters?.isFilterSet
                        ? lanes
                        : shippers
                    : currTab === NETWORK_TABLE_TYPE.LANES
                    ? loadFilters?.isFilterSet
                        ? shippers
                        : lanes
                    : reportDomiciles || [];

            const selectedList =
                currTab === NETWORK_TABLE_TYPE.SHIPPERS
                    ? loadFilters?.isFilterSet
                        ? selectedLanes
                        : selectedShippers
                    : currTab === NETWORK_TABLE_TYPE.LANES
                    ? loadFilters?.isFilterSet
                        ? selectedShippers
                        : selectedLanes
                    : checked?.selectedDomicile || [];

            const allRows = originalList?.length === selectedList?.length;
            let filter = { load_type } as any;
            if (compare_run) filter.compare_run_id = compare_run;
            if ((load_type as any) === LOAD_TYPES.all) filter.load_type = 'all';
            else if (load_type === LOAD_TYPES.fleet) filter.load_type = 'accepted';
            else if (load_type === LOAD_TYPES.external) filter.load_type = 'rejected';

            if (networkChecked) {
                if (!allRows && currTab === NETWORK_TABLE_TYPE.SHIPPERS)
                    filter.shipper_ids = selectedShippers.join(',');
                if (!allRows && currTab !== NETWORK_TABLE_TYPE.SHIPPERS)
                    filter.lanes = selectedLanes.join(',');
            }

            if (loadFilters?.isFilterSet) {
                if (currTab === NETWORK_TABLE_TYPE.SHIPPERS && shippers?.length === 1)
                    filter.lanes = selectedLanes.join(',');
                else if (lanes?.length === 1) filter.shipper_ids = selectedShippers.join(',');
            }

            filter = {
                ...filter,
                ...getLoadSourceQueryParam(loadSource),
                ...getFreightBonusQueryParam(isFreightBonusApplied)
            };
            !isListView && dispatch(getFlowData(null, base_run, filter));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            base_run,
            compare_run,
            loadFilters,
            reportFilters,
            networkChecked,
            loadSource,
            isListView,
            isFreightBonusApplied
        ]);
    };

    const useResetWhenUnmountStateEffect = () => {
        useEffect(() => {
            return () => {
                (async () => {
                    await dispatch(updateLoadSource(LOAD_SOURCE.ALL));
                    await dispatch(setConfiguration({}));
                    await dispatch(setDraftConfigurationActions([]));

                    //TODO need to create a function to reset the pop up comps states in network view.
                    dispatch(
                        updateNetworkView({
                            showHistorySlideout: false,
                            showBidFilesSlideout: false,
                            showNetworkTableSettingsSlideout: false,
                            showDriverOptionBar: false,
                            showDownloadModal: false,
                            showParameterDialog: false,
                            showDriveMoveDetails: false,
                            appliedBidFiles: []
                        })
                    );
                })();
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    };

    const useRemoveAggAlertStateEffect = (isAggregated: boolean, alertsContext: any) => {
        useEffect(() => {
            if (!allowEditOnAggregatedDrivers && !isAggregated) {
                alertsContext.removeAlert('zip_aggregation');
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isAggregated]);
    };

    const useResetShipperLaneStateEffect = (baseReport: any, isShipperTab: any) => {
        useEffect(() => {
            if (baseReport?.id && baseReport?.id != base_run) {
                dispatch(
                    updateNetworkView({
                        checked: {
                            allShippers: false,
                            allLanes: false,
                            selectedShippers: [],
                            selectedLanes: []
                        }
                    })
                );
                dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [baseReport, base_run, isShipperTab]);
    };

    const useGetActionsByConfigIdEffect = () => {
        useEffect(() => {
            if (config_id) dispatch(getConfigurationActions(base_run, config_id));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [config_id]);
    };

    const useLayoutResetDriverStateEffect = (resetDriverUseRef: any) => {
        //TODO check to see if this is being triggered.
        const triggerEndpoints = async () => {
            dispatch(updateNetworkView({ loadingResetDriverState: true }));

            if (base_run) {
                await dispatch(getReportByReportId(base_run, true, !compare_run));
            }

            await dispatch(getInitialRunData({ reportRunId: base_run } as any));
            await dispatch(getConfigurationByRunId(base_run));
            dispatch(setConfiguration({}));
            dispatch(setDraftConfigurationActions([]));
            dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DEFAULT }));
            dispatch(updateNetworkView({ loadingResetDriverState: false }));
        };

        useLayoutEffect(() => {
            if (resetDriverUseRef.current) {
                resetDriverUseRef.current = false;
                return;
            }
            triggerEndpoints();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [base_run]);
    };

    const useUpdateKPIForDriverEffect = (currTab: any) => {
        //TODO check to see if this is being triggered.
        const updateKPI = async () => {
            let baseFilter = compare_run ? { compare_run_id: compare_run } : {};
            dispatch(updateNetworkView({ loadingKPIForDriver: true }));
            await dispatch(
                getKPIbyReportId({
                    runId: base_run,
                    filter: baseFilter,
                    v2KpIsFlag: v2KpIs
                })
            );
            dispatch(updateNetworkView({ loadingKPIForDriver: false }));
        };

        useEffect(() => {
            if (currTab === NETWORK_TABLE_TYPE.DRIVERS) {
                dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DEFAULT }));
                updateKPI();
                dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [aggregationLevel, currTab]);
    };

    const useLayoutGetDomicilesByIdEffect = (getDomicilesByIdUseRef: any) => {
        //TODO check to see if this is being triggered.
        const fetchDomiciles = async () => {
            let filtered = {};
            if (compare_run) {
                filtered = { compare_run_id: compare_run, aggregation_level: aggregationLevel };
            } else {
                filtered = { aggregation_level: aggregationLevel };
            }

            await dispatch(getReportDomiciles(base_run, filtered));
        };

        useLayoutEffect(() => {
            if (getDomicilesByIdUseRef.current) {
                getDomicilesByIdUseRef.current = false;
                return;
            }
            fetchDomiciles();

            dispatch(
                updateDriverReducer({
                    checked: {
                        ...checked,
                        selectedDomicile: [],
                        selectedDriverType: []
                    }
                })
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [aggregationLevel, base_run, compare_run]);
    };

    const useSetReportsStateEffect = (setAction: any) => {
        useEffect(() => {
            updateBaseAndCompareReportState();
            setAction('');
            if (!mapBoxKey) dispatch(getMapboxKey());
        }, [setAction]);
    };

    const useInitialEffect = (baseReport: any, alertsContext: any) => {
        useEffect(() => {
            !isListView && dispatch(getFlowData(null, base_run));
            dispatch(
                updateNetworkView({
                    enableEditRow: false,
                    currTab: NETWORK_TABLE_TYPE.SHIPPERS,
                    isListView: true
                })
            );
            alertsContext.resetAllAlerts();
            dispatch(settingsClear());

            if (baseReport?.is_bid_file_outdated) {
                alertsContext.addAlert('outdated_bid');
            } else {
                alertsContext.removeAlert('outdated_bid');
            }

            dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.NETWORK_VIEW }));
            dispatch(initializeSelectedReports({ base_run: base_run, compare_run: compare_run }));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    };

    // Get the initial max aggregation level for report
    // useEffect(() => {
    //     dispatch(getFlowData(null, base_run));
    //     const queryParams = {};
    //     const filtered = { ...queryParams, aggregation_level: 5 };
    //     dispatch(getReportDomiciles(base_run, filtered, true));
    //     dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.NETWORK_VIEW }));
    //     dispatch(initializeSelectedReports({ base_run: base_run, compare_run: compare_run }));
    // }, []);

    // useEffect(() => {
    //     dispatch(
    //         updateNetworkView({
    //             enableEditRow: false,
    //             currTab: NETWORK_TABLE_TYPE.SHIPPERS,
    //             isListView: true
    //         })
    //     );
    //     alertsContext.resetAllAlerts();
    //     dispatch(
    //         getTableDataByIdAndFilters(
    //             base_run,
    //             {
    //                 compare_run_id: compare_run,
    //                 ...getLoadSourceQueryParam(loadSource),
    //                 ...getFreightBonusQueryParam(isFreightBonusApplied)
    //             },
    //             v2KpIs
    //         )
    //     );
    //     dispatch(settingsClear());

    //     let queryParams = {};
    //     let filtered = {};
    //     if (compare_run) {
    //         filtered = { ...queryParams, compare_run_id: compare_run };
    //     } else {
    //         filtered = { ...queryParams, aggregation_level: aggregationLevel };
    //     }

    //     dispatch(getReportDomiciles(base_run, filtered));
    //     if (baseReport?.is_bid_file_outdated === true) {
    //         alertsContext.addAlert('outdated_bid');
    //     } else {
    //         alertsContext.removeAlert('outdated_bid');
    //     }
    // }, []);

    const useUpdateCurrentSimulationStateEffect = () => {
        useEffect(() => {
            if (config_id && !compare_run) {
                const configId = parseInt(config_id);
                updateCurrSimulation(configId);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [configurations, config_id, compare_run]);
    };

    const useResetDriverStateEffect = (resetDriverChecked: any, currTab: any) => {
        useEffect(() => {
            if (currTab === NETWORK_TABLE_TYPE.DRIVERS) {
                let filtered = {};
                filtered = compare_run ? { compare_run_id: compare_run } : { aggregation_level: aggregationLevel };
                dispatch(getReportDomiciles(base_run, filtered));
            }
            dispatch(setLoadFilters({ ...loadFilters, filterBy: {}, isFilterSet: false }));
            dispatch(updateDriverReducer({ checked: resetDriverChecked }));
            dispatch(updateNetworkView({ driverMode: DRIVER_MODE.DEFAULT }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currTab]);
    };

    const useLayoutGetLocationByReportIdEffect = () => {
        useEffect(() => {
            if (configuration?.id) {
                dispatch(
                    getLocationByReportId({
                        runId: compare_run || base_run,
                        filter: { configuration_id: configuration.id }
                    })
                );
            } else {
                dispatch(getLocationByReportId({ runId: compare_run || base_run }));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [base_run, compare_run, configuration, configurationActions]);
    };

    const useGetReportsOrDriverTypesById = () => {
        useEffect(() => {
            if (bucket_id) {
                dispatch(getReportsByBucketOrReportId(bucket_id, 'bucket'));
            } else {
                dispatch(getReportsByBucketOrReportId(base_run, 'report'));
            }
            dispatch(getDriverTypesByReportId(compare_run || base_run));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [bucket_id, base_run, compare_run]);
    };

    useEffect(() => {
        if (bucket_id) {
            dispatch(getReportsByBucketOrReportId(bucket_id, 'bucket'));
        } else {
            dispatch(getReportsByBucketOrReportId(base_run, 'report'));
        }

        let filter = compare_run
            ? {
                  load_type: 'accepted',
                  compare_run_id: compare_run
              }
            : {};

        dispatch(
            getKPIbyReportId({
                runId: base_run,
                filter,
                v2KpIsFlag: v2KpIs
            })
        );

        dispatch(getDriverTypesByReportId(compare_run || base_run));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bucket_id, base_run, compare_run]);

    const useResetAllStateEffect = (checked: any, currTab: any) => {
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(
                updateNetworkView({
                    checked: {
                        allShippers: false,
                        allLanes: false,
                        selectedShippers: [],
                        selectedLanes: []
                    }
                })
            );
            dispatch(
                updateDriverReducer({
                    checked: {
                        ...checked,
                        allDomiciles: false,
                        selectedDomicile: []
                    }
                })
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currTab]);
    };

    const useUpdateOutdatedBidEffect = (
        baseReport: any,
        compareReport: any,
        alertsContext: any
    ) => {
        useEffect(() => {
            if (baseReport?.is_bid_file_outdated) {
                alertsContext.addAlert('outdated_bid');
            } else {
                alertsContext.removeAlert('outdated_bid');
            }
            // eslint-disable-next-line eqeqeq
            if (
                (baseReport?.id && baseReport?.id != base_run) ||
                compareReport?.id != compare_run
            ) {
                dispatch(resetReportDataPreserveContext());
                dispatch(settingsClear());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [baseReport, base_run, compareReport, compare_run]);
    };

    const useLayoutGetTableDataByIdAndFilterEffect = (secondUpdate: any) => {
        useLayoutEffect(() => {
            if (secondUpdate.current) {
                secondUpdate.current = false;
                return;
            }

            if (compare_run) {
                dispatch(setConfiguration({}));
                dispatch(getReportByReportId(compare_run, false));
            }

            if (!loadFilters?.isFilterSet) {
                let filterObj = { is_bid: false };
                if (loadSource !== LOAD_SOURCE.ALL)
                    filterObj.is_bid = loadSource === LOAD_SOURCE.BID ? true : false;
                dispatch(
                    getTableDataByIdAndFilters(
                        base_run,
                        {
                            compare_run_id: compare_run,
                            ...getLoadSourceQueryParam(loadSource),
                            ...getFreightBonusQueryParam(isFreightBonusApplied)
                        },
                        v2KpIs
                    )
                );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [compare_run]);
    };

    const useGetAppliedBidsByConfigIdEffect = (baseReport: any) => {
        useEffect(() => {
            if (
                Object.keys(configuration).length === 0 &&
                baseReport?.report_status === REPORT_STATUS.COMPLETED
            ) {
                if (Boolean(baseReport?.configuration)) {
                    // * The fetch executes when viewing a completed report with no draft configuration currently open because our code already handles fetching applied bids for draft configurations. See updateCurrSimulation.

                    dispatch(getReportAppliedBids(baseReport.configuration));
                } else dispatch(setAppliedBids([]));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [baseReport]);
    };

    const useLayoutResetTableDataPreserveEffect = (
        resetTableDataPreserveUseRef: any,
        baseReport: any,
        compareReport: any
    ) => {
        const dispatch = useDispatch();
        const { base_run, compare_run }: useParamsProps = useParams();

        useLayoutEffect(() => {
            if (resetTableDataPreserveUseRef.current) {
                resetTableDataPreserveUseRef.current = false;
                return;
            }

            if (
                (baseReport?.id && baseReport?.id != base_run) ||
                compareReport?.id != compare_run
            ) {
                dispatch(resetReportDataPreserveContext());
                refreshNetworkViewTable();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [baseReport, compareReport, base_run, compare_run]);
    };

    const useUpdateKPIForShipperLaneEffect = () => {
        useEffect(() => {
            let baseFilter = compare_run ? { compare_run_id: compare_run } : {};
            if (currTab !== NETWORK_TABLE_TYPE.DRIVERS) {
                const filterObj = initialLoadsKPIFilterObj(
                    loadSource,
                    reportFilters,
                    loadFilters?.isFilterSet,
                    currTab
                );

                dispatch(
                    getKPIbyReportId({
                        runId: base_run,
                        filter: {
                            ...filterObj,
                            ...baseFilter
                        },
                        v2KpIsFlag: v2KpIs
                    })
                );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [base_run, loadSource, currTab, loadFilters?.isFilterSet]);
    };

    const useRefreshTableOnlyEffect = () => {
        useEffect(() => {
            refreshNetworkViewTable();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [loadSource, isFreightBonusApplied]);
    };

    return {
        useGetFlowDataEffect,
        useResetWhenUnmountStateEffect,
        useRemoveAggAlertStateEffect,
        useResetShipperLaneStateEffect,
        useGetActionsByConfigIdEffect,
        useLayoutResetDriverStateEffect,
        useUpdateKPIForDriverEffect,
        useLayoutGetDomicilesByIdEffect,
        useSetReportsStateEffect,
        useInitialEffect,
        useUpdateCurrentSimulationStateEffect,
        useResetDriverStateEffect,
        useLayoutGetLocationByReportIdEffect,
        useGetReportsOrDriverTypesById,
        useResetAllStateEffect,
        useUpdateOutdatedBidEffect,
        useLayoutGetTableDataByIdAndFilterEffect,
        useGetAppliedBidsByConfigIdEffect,
        useLayoutResetTableDataPreserveEffect,
        useUpdateKPIForShipperLaneEffect,
        useRefreshTableOnlyEffect
    };
};

export default useNetworkViewEffects;

// export const useRemoveLoadingEffect = (loadingStatus: string) => {
//     const dispatch = useDispatch();
//     useEffect(() => {
//         dispatch(updateLoadingState({ showLoading: false }));
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [loadingStatus]);
// };

// export const useLayoutGetKPIByReportIdEffect = (fourthUpdate: any) => {
//     const dispatch = useDispatch();
//     const { base_run, compare_run }: useParamsProps = useParams();
//     const { v2KpIs } = useFlags();

//     useLayoutEffect(() => {
//         if (fourthUpdate.current) {
//             fourthUpdate.current = false;
//             return;
//         }
//         let filter = compare_run
//             ? {
//                   load_type: 'accepted',
//                   compare_run_id: compare_run
//               }
//             : {};

//         dispatch(
//             getKPIbyReportId({
//                 runId: base_run,
//                 filter,
//                 v2KpIsFlag: v2KpIs
//             })
//         );
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [compare_run, base_run]);
// };

/* State changes only */
