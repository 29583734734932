/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import EditBidConfigTable from './EditBidConfigTable/EditBidConfigTable';
import API from 'utils/axios';
import { env } from 'env';
import { useDispatch, useSelector } from 'react-redux';
import { RequestQuote, FileUpload, ListAlt, Add } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import palette from 'theme/palette';
import {
    TopHeader,
    ODButton,
    TableBanner,
    ODModal,
    ODDrawer,
    ODTable,
    HelperMessage
} from 'shared';
import { BIDDING_TABLE_NAME, MODAL_TYPE, SLIDE_OUT_TYPE } from 'constants/bidding';
import { BUTTON_TYPE } from 'constants/colors';
import {
    getAllGroups,
    getAllBidFiles,
    resetGroupSlideOut,
    updateBiddingState,
    settingsUpdate
} from 'redux/actions';

import { useNotificationHook } from 'utils/hooks/notificationHooks';
import BidGroupList from './BidGroupList';
import BidFileDetailsForm from './BidFileDetailsForm';
import HandleGroupForm from './HandleGroupForm';
import BidModalWrapper from './BidModalWrapper';
import BidFileManagerActionBar from './BidFileManagerActionBar';
import bidFileManagerColumnsInitiate from './BidFileManagerColumns';
import ODTableColumnSelectionsSlideout from 'shared/ODTable/ODTableColumnSelectionSlideout';
import EditBidFileForm from './EditBidFileForm';
import AssociatedReports from './AssociatedReports/AssociatedReports';
import CompletedModal from 'components/Modals/CompletedModal';
import AlertsContainer from 'components/NetworkView/Alerts/AlertsContainer';
import { useAlerts } from 'components/NetworkView/Alerts/AlertsContext';
import { LEFTNAV_OPTION, PREFERRED_RATE_TYPE } from 'constants/settings';
import { FILE_TYPE } from 'constants/bidding';
import { REPORT_STATUS } from 'constants/network';
import { ODTableProvider } from 'shared/ODTable/ODTableContext';
import { produce } from 'immer';
import * as FS from '@fullstory/browser';
import BidConfigSettingsSlideout from 'components/Slideouts/BidConfigSettingsSlideout';
import { useNavigate } from 'react-router-dom';
import { FlatfileSpace } from './FlatfileNewVersion/FlatfileSpace';
import { updateFlatfileSpaceMetadata } from './FlatfileNewVersion/utils';

const BidFileManagerContainer = styled(Box)(() => ({
    backgroundColor: palette.neutral.white,
    height: '100vh',
    overflow: 'hidden'
}));

const LeftSection = styled(Box)(() => ({
    minWidth: '280px',
    padding: '16px',
    borderRight: `1px solid ${palette.neutral.neutral1}`
}));

const RightSection = styled(Box)(({ bidFileManagerSelectedConfigIds, bidManagerV2 }) => ({
    backgroundColor: palette.neutral.neutral25,
    width: '100%',
    padding: '16px',
    paddingTop: bidManagerV2 ? 0 : '16px',
    overflow: 'hidden',
    position: 'relative',
    height:
        bidFileManagerSelectedConfigIds?.length > 0 ? 'calc(100vh - 88px)' : 'calc(100vh - 102px)',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
        width: 0
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none'
}));

const BidGroupTableContainer = styled(Box)(() => ({
    height: 'calc(100vh - 200px)',
    overflowY: 'auto'
}));

const ActionBarContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px'
}));

const BidFileManagerNewFlatFile = () => {
    const dispatch = useDispatch();
    const { newNotificationFlow, odpt4185BidConfigUpdate, bidManagerV2 } = useFlags();
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [showFlatFileSpace, setShowFlatFileSpace] = useState(false);
    const [spaceId, setSpaceId] = useState(null);
    const [openBidConfig, setOpenBidConfig] = useState(false);
    const [openAssocReports, setOpenAssocReports] = useState(false);
    const [bidConfigId, setBidConfigId] = useState(undefined);
    const [bidShippers, setBidShippers] = useState({});
    const [bids, setBids] = useState([]);
    const [rowsToDelete, setRowsToDelete] = useState([]);
    // const [globalFilter, setGlobalFilter] = useState('');
    const navigate = useNavigate();

    const alertsContext = useAlerts();
    const { isCompletedReport } = useSelector((state) => state.NetworkReducer);
    const { openBidConfigSettingSlideout } = useSelector(
        (state) => state.BiddingReducer
    );
    useNotificationHook();
    //TODO: figure out if this will be used in the future
    // const searchBarGlobalFilter = (row, columnId, filterValue) => {
    //     const search = filterValue.toLowerCase();
    //     let value = row.getValue(columnId);
    //     if (typeof value === 'number') value = String(value);
    //     return value?.toLowerCase().includes(search);
    // };

    const {
        allBidFiles,
        currSelectedGroups,
        allGroups,
        slideoutMode,
        bidFileManagerSelectedConfigIds,
        showTableSettings
    } = useSelector((state) => state.BiddingReducer);

    const { hiddenColumnsArrays } = useSelector((state) => state.TableLayoutReducer);

    const slideoutHeading =
        slideoutMode?.mode !== SLIDE_OUT_TYPE.NOSHOW
            ? slideoutMode?.mode === SLIDE_OUT_TYPE.EDIT_GROUP
                ? 'edit group'
                : 'create new group'
            : '';

    const fetchShippersBySubdomain = async () => {
        const subdomain = window.location.host.includes('localhost')
            ? env.REACT_APP_CONNECTION
            : window.location.host.split('.')[0];

        await API.get(`/bidding/shippers/${subdomain}/`).then((res) => {
            const result = res.data.results;
            setBidShippers(result);
        });
    };

    const updateBidFileManagerConfigIds = (arr) => {
        dispatch(updateBiddingState({ bidFileManagerSelectedConfigIds: arr }));
    };

    const handleOpenEditBidConfigTable = async (info) => {
        const status = info?.row?.original?.status || info?.status;
        const configId = info?.row?.original?.id || info?.id;
        const defaultRateType = info?.row?.original?.default_rate_type || info?.default_rate_type;
        await setBidConfigId(configId);

        if (status === FILE_TYPE.DRAFT || status === REPORT_STATUS.COMPLETED) {
            odpt4185BidConfigUpdate ? navigate(`/bidding/${configId}`) : setOpenBidConfig(true);
        } else {
            await dispatch(
                updateBiddingState({
                    defaultRateType: defaultRateType || PREFERRED_RATE_TYPE.PER_MILE
                })
            );
            setShowFlatFileSpace(true);
        }
    };

    useEffect(() => {
        if(spaceId && bidConfigId) {
            const dataToSend = { currConfigId: bidConfigId };
            updateFlatfileSpaceMetadata(spaceId, dataToSend);
            return () => {};
        }
    }, [spaceId, bidConfigId]);

    const handleOnAssocReportsClick = (info) => {
        setBidConfigId(info.row.original.id);
        setOpenAssocReports(true);
    };

    const bidFileManagerColumns = bidFileManagerColumnsInitiate(
        handleOpenEditBidConfigTable,
        handleOnAssocReportsClick,
        'inclusion'
    );

    useEffect(() => {
        dispatch(getAllGroups());
        dispatch(getAllBidFiles());
        dispatch(
            updateBiddingState({
                bidFileManagerSelectedConfigIds: []
            })
        );
        fetchShippersBySubdomain();
        dispatch(settingsUpdate({ currSelectedMenuItem: LEFTNAV_OPTION.BID_MANAGER }));
    }, []);

    useEffect(() => {
        const selectedIds = allGroups.map((group) => group?.id);
        dispatch(
            updateBiddingState({
                currSelectedGroups: selectedIds
            })
        );
    }, [allGroups]);
    const resetFlatFileInfo = () => {
        setBidConfigId(null);
        dispatch(
            updateBiddingState({
                defaultRateType: null
            })
        );
    };

    useEffect(() => {
        let filteredBidFileManagerData;

        if (allGroups?.length === currSelectedGroups?.length) {
            filteredBidFileManagerData = allBidFiles.map((file) => ({
                ...file,
                disableDownload:
                    file?.status === REPORT_STATUS.DRAFT || file?.status === FILE_TYPE.EMPTY
            }));
        } else {
            let filteredFilesByGroup = allBidFiles?.filter((bidFile) =>
                currSelectedGroups.includes(bidFile?.group?.id)
            );
            filteredBidFileManagerData = filteredFilesByGroup.map((file) => ({
                ...file,
                disableDownload:
                    file?.status === REPORT_STATUS.DRAFT || file?.status === FILE_TYPE.EMPTY
            }));
        }

        filteredBidFileManagerData.forEach((row) => {
            if (row.group && row.group.name) row.groupName = row.group.name;
            else {
                row.groupName = null;
            }
            row.start_date = new Date(row.start_date);
            row.end_date = new Date(row.end_date);
        });

        setBids(filteredBidFileManagerData);
    }, [allBidFiles, currSelectedGroups]);

    const handleCellEdits = (cellEdits) => {
        const modifiedDataRows = produce(bids, (draft) => {
            cellEdits.forEach((cellEdit) => {
                const { rowId, columnId, newValue } = cellEdit;
                const rowToUpdate = draft.find((row) => row.id === rowId);
                if (rowToUpdate) {
                    rowToUpdate[columnId] = newValue;
                }
            });
        });
        setBids(modifiedDataRows);
    };
    const closeBidConfigSetting = () => {
        dispatch(updateBiddingState({ openBidConfigSettingSlideout: false }));
    };
    return (
        <BidFileManagerContainer>
            {openUploadModal && (
                <ODModal
                    show={true}
                    heading={'Bid File Details'}
                    handleClose={() => setOpenUploadModal(false)}
                    actionButtonText={'confirm'}
                    enableCustomizedButton={true}
                    children={
                        <BidFileDetailsForm
                            bidConfigId={bidConfigId}
                            spaceId={spaceId}
                            bidShippers={bidShippers}
                            setBidConfigId={setBidConfigId}
                            handleClose={() => setOpenUploadModal(false)}
                            resetFlatFileInfo={resetFlatFileInfo}
                            setShowFlatFileSpace={setShowFlatFileSpace}
                        />
                    }
                />
            )}
            {showFlatFileSpace && <FlatfileSpace setShowFlatFileSpace={setShowFlatFileSpace} setSpaceId={setSpaceId}/>}
            <BidModalWrapper />
            <ODDrawer
                open={
                    slideoutMode?.mode === SLIDE_OUT_TYPE.CREATE_GROUP ||
                    slideoutMode?.mode === SLIDE_OUT_TYPE.EDIT_GROUP
                }
                title={slideoutHeading}
                anchor="left"
                onClose={() => dispatch(resetGroupSlideOut())}
                enableCustomizedButton={true}
                children={<HandleGroupForm handleClose={() => dispatch(resetGroupSlideOut())} />}
            />
            <ODDrawer
                open={slideoutMode?.mode === SLIDE_OUT_TYPE.EDIT_FILE}
                title="Edit File"
                anchor="right"
                onClose={() => dispatch(resetGroupSlideOut())}
                enableCustomizedButton={true}
                children={<EditBidFileForm handleClose={() => dispatch(resetGroupSlideOut())} />}
            />

            <TopHeader
                title="Bid File Manager"
                color={palette.black}
                icon={<RequestQuote />}
                backgroundColor={palette.neutral.white}
                content={
                    <ODButton
                        buttonType={BUTTON_TYPE.TRANSPARENT}
                        buttonText="Upload Bids"
                        endIcon={<FileUpload />}
                        onClick={() => {
                            dispatch(
                                updateBiddingState({
                                    defaultRateType: null
                                })
                            );
                            window.FS.event('Bid_File_Upload_Click Upload_Bids_Button ', {});
                            setOpenUploadModal(true);
                        }}
                    />
                }
            />

            <Box aria-label={`table for bid file manager`} component="section" display="flex">
                <LeftSection>
                    <TableBanner
                        startIcon={<ListAlt />}
                        title="Groups"
                        endIcon={<Add />}
                        endIconTooltip={'Create new group'}
                        onClick={() => {
                            dispatch(
                                updateBiddingState({
                                    slideoutMode: { mode: SLIDE_OUT_TYPE.CREATE_GROUP, data: {} }
                                })
                            );
                            window.FS.event(
                                'bid_file_manager_bidupload_flatfile_triggered_succesfully',
                                {}
                            );
                        }}
                    />
                    <BidGroupTableContainer data-testid="bid-group-list">
                        <BidGroupList />
                    </BidGroupTableContainer>
                </LeftSection>

                <RightSection
                    bidManagerV2={bidManagerV2}
                    bidFileManagerSelectedConfigIds={bidFileManagerSelectedConfigIds}
                    aria-label="bid file table section">
                    {!bidManagerV2 && (
                        <HelperMessage
                            message={
                                'Bid files status is labeled "Completed" once a rate has been set for all lanes. Completed bid files can be added to base networks to create bid analysis.'
                            }
                        />
                    )}

                    {bidFileManagerSelectedConfigIds?.length > 0 && (
                        <ActionBarContainer>
                            <BidFileManagerActionBar
                                setOpenBidConfig={handleOpenEditBidConfigTable}
                                checkedFileIds={bidFileManagerSelectedConfigIds}
                                bids={bids}
                            />
                        </ActionBarContainer>
                    )}

                    <ODTableProvider
                        checkedRows={bidFileManagerSelectedConfigIds}
                        columnDefs={bidFileManagerColumns}
                        hiddenColumnIds={hiddenColumnsArrays[BIDDING_TABLE_NAME.BID_MANAGER]}
                        cellEditsCallback={handleCellEdits}
                        data={bids}
                        deleteOption={true}
                        downloadOption={true}
                        editOption={true}
                        handleClickDeleteOption={(rowInfo) => {
                            dispatch(
                                updateBiddingState({
                                    modalMode: {
                                        mode: MODAL_TYPE.DELETE_FILE,
                                        data: {
                                            id: rowInfo.id,
                                            data: [rowInfo]
                                        }
                                    }
                                })
                            );
                        }}
                        handleClickDownloadOption={(rowInfo) => {
                            dispatch(
                                updateBiddingState({
                                    modalMode: {
                                        mode: MODAL_TYPE.DOWNLOAD_FILE,
                                        data: {
                                            id: rowInfo.id,
                                            data: [rowInfo]
                                        }
                                    }
                                })
                            );
                        }}
                        handleClickEditOption={(rowInfo) => {
                            dispatch(
                                updateBiddingState({
                                    slideoutMode: {
                                        mode: SLIDE_OUT_TYPE.EDIT_FILE,
                                        data: {
                                            id: rowInfo.id,
                                            data: rowInfo
                                        }
                                    }
                                })
                            );
                        }}
                        handleClickSettingsIcon={() => {
                            FS.event(
                                'Bid File Manager_Table Settings_Click Table Settings Icon',
                                {}
                            );
                            dispatch(updateBiddingState({ showTableSettings: true }));
                        }}
                        interactiveTable={true}
                        interactiveColumns={[]}
                        rowsToDelete={rowsToDelete}
                        setCheckedRows={updateBidFileManagerConfigIds}
                        setRowsToDelete={setRowsToDelete}>
                        <ODTable tableKey="bid-file-manager-table" data-testid="bid-file-table" />
                        <ODTableColumnSelectionsSlideout
                            tableName={BIDDING_TABLE_NAME.BID_MANAGER}
                            onClose={() =>
                                dispatch(updateBiddingState({ showTableSettings: false }))
                            }
                            open={showTableSettings}
                        />
                    </ODTableProvider>
                </RightSection>

                {openBidConfig && (
                    <EditBidConfigTable
                        open={openBidConfig}
                        setOpenBidConfig={setOpenBidConfig}
                        bidConfigId={bidConfigId || bidFileManagerSelectedConfigIds[0]}
                    />
                )}
                {openAssocReports && (
                    <AssociatedReports
                        open={openAssocReports}
                        setOpenAssocReports={setOpenAssocReports}
                        bidConfigId={bidConfigId || bidFileManagerSelectedConfigIds[0]}
                    />
                )}
            </Box>
            {isCompletedReport.show && newNotificationFlow && <CompletedModal />}
            {newNotificationFlow && <AlertsContainer alerts={alertsContext?.alerts} />}
            {/* TODO: Remove when removing odpt4185BidConfigUpdate ff */}
            <BidConfigSettingsSlideout
                show={openBidConfigSettingSlideout}
                onClose={closeBidConfigSetting}
            />
        </BidFileManagerContainer>
    );
};

export default BidFileManagerNewFlatFile;
