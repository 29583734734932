import { useState, useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box } from '@mui/material';
import { max, min } from 'lodash';
import DateTimePickerWrapper from './DateTimePickerWrapper';
import moment from 'moment';
import theme from 'theme';
import { ODButton } from 'shared';
import { BUTTON_TYPE } from 'constants/colors';
import ClearFilterButton from './ClearFilterButton';
import { EndAdornmentsContainer } from './FilterStyles';

const DateTimeFilter = (tableData) => {
    const { column, filteredRows, initialRows } = tableData;
    const { setFilter, filterValue } = column;

    const [minVal, setMin] = useState(null);
    const [maxVal, setMax] = useState(null);
    const [unfilteredMin, setUnfilteredMin] = useState(null);
    const [unfilteredMax, setUnfilteredMax] = useState(null);

    const initialColumnValues = initialRows?.map((r) => r?.original[column.id]);
    const stringifiedInitialValues = JSON.stringify(initialColumnValues);
    const filteredColumnVals = filteredRows?.map((r) => r?.original[column.id]);
    const stringifiedFilteredVals = JSON.stringify(filteredColumnVals);

    useEffect(() => {
        setUnfilteredMin(new Date(min(initialColumnValues)));
        setUnfilteredMax(new Date(max(initialColumnValues)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedInitialValues]);

    useEffect(() => {
        setFilter([null, null]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setMin(new Date(min(filteredColumnVals)));
        setMax(new Date(max(filteredColumnVals)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [column.id, stringifiedFilteredVals]);

    const safeMomentFormat = (value) => {
        const date = moment(value, moment.ISO_8601, true);
        return date.isValid() ? date.format('MM/DD/YY hh:mm a') : '';
    };

    const renderFilterButton = ({ isActive, clickHandler, limitType }) => {
        const value = filterValue && filterValue[limitType === 'min' ? 0 : 1];
        return (
            <ODButton
                sx={{
                    height: '22px',
                    padding: 0,
                    paddingLeft: 0,
                    borderRadius: '2px',
                    fontSize: '10px',
                    textTransform: 'none',
                    boxSizing: 'content-box',
                    width: '120px',
                    border: isActive ? '1px solid transparent' : `1px solid ${theme.palette.neutral.neutral2}`,
                    backgroundColor: isActive ? theme.palette.neutral.neutral2 : 'none',
                    color: value ? theme.palette.black : theme.palette.ODBlue.odBlue7
                }}
                buttonType={BUTTON_TYPE.LIGHT}
                type="submit"
                buttonText={
                    value
                        ? safeMomentFormat(value)
                        : (limitType === 'min' ? minVal : maxVal)
                        ? safeMomentFormat(limitType === 'min' ? minVal : maxVal)
                        : ''
                }
                onClick={clickHandler}
                endIcon={
                    <EndAdornmentsContainer>
                        {value && (
                            <ClearFilterButton
                                clickHandler={() => {
                                    setFilter(
                                        limitType === 'min'
                                            ? [null, filterValue[1]]
                                            : [filterValue[0], null]
                                    );
                                }}
                                color={theme.palette.black}
                            />
                        )}
                    </EndAdornmentsContainer>
                }
            />
        );
    };

    return (
        <Box
            id={`${column.id}-datetime-filter`}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ marginRight: '2px' }}>
                    <DateTimePickerWrapper
                        selectedValue={filterValue && filterValue[0]}
                        setSelectedValue={(val) => setFilter([val, filterValue[1]])}
                        limit={minVal}
                        unfilteredMax={unfilteredMax}
                        unfilteredMin={unfilteredMin}
                        inputRenderingFn={({ isActive, clickHandler }) =>
                            renderFilterButton({ isActive, clickHandler, limitType: 'min' })
                        }
                    />
                    <Box sx={{ fontSize: '8px', marginTop: '2px', fontWeight: '400' }}>Min</Box>
                </Box>
                <Box>
                    <DateTimePickerWrapper
                        selectedValue={filterValue && filterValue[1]}
                        setSelectedValue={(val) => setFilter([filterValue[0], val])}
                        limit={maxVal}
                        unfilteredMax={unfilteredMax}
                        unfilteredMin={unfilteredMin}
                        inputRenderingFn={({ isActive, clickHandler }) =>
                            renderFilterButton({ isActive, clickHandler, limitType: 'max' })
                        }
                    />
                    <Box sx={{ fontSize: '8px', marginTop: '2px', fontWeight: '400' }}>Max</Box>
                </Box>
            </LocalizationProvider>
        </Box>
    );
};

export default DateTimeFilter;
