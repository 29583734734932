import { FlatfileListener } from '@flatfile/listener';
import api from '@flatfile/api';
import { bulkRecordHook, recordHook } from '@flatfile/plugin-record-hook';
import { createFlatfileNew, getAllBidFiles, getAllGroups, updateBiddingState } from 'redux/actions';

export const createListener = (dispatch: any, navigate: any, flags: any) => {
    const listener = FlatfileListener.create((listener) => {
        listener.on('**', async (event) => {
            console.log(`Received event: ${event.topic}`);
        });

        listener.use(
            recordHook('bid_files', (record: any) => {
                const firstName = record.get('origin_zip');
                console.log({ firstName });
                // record.set('origin_city', 'Rock');
                console.log('record', record);
                return record;
            })
        );
        listener.use(
            bulkRecordHook(
                'bid_files',
                (records) => {
                console.log('records', records);
                    const formattedRecords = records.map((r) => {
                        // Perform operations on each record
                        r.set('sales_ranking', 1);
                        return r;
                    });
                    console.log('formattedRecords', formattedRecords);
                    return formattedRecords;
                },
                { chunkSize: 100, parallel: 2 }
            )
        );

        listener.filter({ job: 'workbook:submitActionFg' }, (configure) => {
            configure.on('job:ready', async (event) => {
                const { context: { spaceId, jobId, workbookId } } = event;
                try {
                    await api.jobs.ack(jobId, {
                        info: 'Getting started.',
                        progress: 10
                    });

                    const space = await api.spaces.get(spaceId);
                    const flatfileSubmitData = space.data.metadata.flatfileSubmitData;
                    
                    const { data: sheets } = await api.sheets.list({ workbookId });
                    const res = await api.records.get(sheets[0].id);
                    const records = res.data.records;
                    const formattedRecords = records.map((record) => {
                        const values = record.values;
                        const bidLane = Object.keys(values).reduce((acc: any, key) => {
                          acc[key] = values[key]?.value || ""; 
                          return acc;
                        }, {});
                        bidLane["preferred_rate_type"] = "rate_per_mile";
                        return bidLane;
                      });
                    console.log('formattedRecords', formattedRecords);

                    const flatfileCreated = await createFlatfileNew(flatfileSubmitData.currConfigId, {bid_lanes: formattedRecords});
                    if (flatfileCreated) {
                        if (flags.odpt4185BidConfigUpdate) {
                            const newlyCreatedBidConfigId = flatfileCreated?.data?.result[0]?.bid_config;
                            navigate(`/bidding/${newlyCreatedBidConfigId}`);
                        }

                        dispatch(updateBiddingState({ currUploadingBidConfigId: '' }));
                        dispatch(getAllGroups());
                        dispatch(getAllBidFiles());
                        dispatch(
                            updateBiddingState({
                                defaultRateType: null
                            })
                        ); 
                    }

                    await api.jobs.complete(jobId, {
                        outcome: {
                            acknowledge: true,
                            message: 'Bid file successfully uploaded!',
                            next: {
                                type: 'wait'
                            }
                        }
                    });
                } catch (error: any) {
                    console.error('Error:', error.stack);

                    await api.jobs.fail(jobId, {
                        outcome: {
                            message: 'This job encountered an error.'
                        }
                    });
                }
            });
        });
    });
    return listener;
}
