import api from '@flatfile/api';

export const updateFlatfileSpaceMetadata = async (spaceId: string, data: any) => {
  try {
    await api.spaces.update(spaceId, {
      metadata: {
        flatfileSubmitData: data
      }
    }
  );
  } catch (error) {
    console.error("Error updating space metadata:", error);
  }
};