import { Autocomplete, Box, TextField } from '@mui/material';
import { useState } from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import theme from 'theme';
import { ExpandMoreSharp } from '@mui/icons-material/';
import { uniq } from 'lodash';
import clsx from 'clsx';
import { useFilterStyles, EndAdornmentsContainer } from './FilterStyles';
import ClearFilterButton from './ClearFilterButton';
const DropdownFilter = ({ column, globalFilteredRows }) => {
    const classes = useFilterStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const optionValues = uniq(
        globalFilteredRows
            .map((row) => row.values[column.id])
            .filter((value) => value !== undefined)
    );

    const { setFilter, filterValue } = column;
    const filterOptions = createFilterOptions({
        matchFrom: 'start'
    });
    return (
        <>
            <Box
                sx={{
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.neutral.neutral4,
                        '&:hover': {
                            backgroundColor: theme.palette.neutral.neutral6
                        }
                    }
                }}>
                <Autocomplete
                    disableClearable
                    disablePortal
                    freeSolo
                    filterOptions={filterOptions}
                    forcePopupIcon={true}
                    id={`${column.id}-dropdown-filter`}
                    options={optionValues}
                    size="small"
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    classes={{
                        popupIndicator: classes.popupIndicator,
                        popper: clsx(classes.popper, classes.lightModePopper),
                        listbox: classes.listbox
                    }}
                    className={clsx(
                        classes.dropdownborder,
                        isOpen
                            ? classes.lightModeAutocompleteOpen
                            : classes.lightModeAutocompleteClosed
                    )}
                    popupIcon={
                        <ExpandMoreSharp
                            sx={{
                                marginBottom: '-14px', //TODO investigate why we have to do this?
                                color:
                                    isOpen || isHovered || filterValue?.length > 0
                                        ? theme.palette.black
                                        : theme.palette.ODBlue.odBlue7
                            }}
                        />
                    }
                    inputValue={filterValue || ''}
                    onChange={(e, value) => {
                        setFilter(value);
                    }}
                    onInputChange={(e, value) => {
                        setFilter(value);
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={clsx(classes.textbox, classes.lightModeTextbox)}
                            {...params}
                            sx={{
                                '& .MuiInputBase-sizeSmall .MuiAutocomplete-input': {
                                    whiteSpace: 'nowrap',
                                    maxWidth: 'calc(100% - 40px)',
                                    color:
                                        isOpen || isHovered || filterValue?.length > 0
                                            ? theme.palette.black
                                            : theme.palette.ODBlue.odBlue73
                                }
                            }}
                            label=""
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <EndAdornmentsContainer>
                                        {filterValue && (
                                            <ClearFilterButton
                                                clickHandler={() => {
                                                    setFilter(undefined);
                                                }}
                                                color={theme.palette.black}
                                            />
                                        )}
                                        {params.InputProps.endAdornment}
                                    </EndAdornmentsContainer>
                                )
                            }}
                        />
                    )}
                />
                <Box sx={{ fontSize: '8px', marginTop: '2px', fontWeight: '400' }}>Search</Box>
            </Box>
        </>
    );
};

export default DropdownFilter;
