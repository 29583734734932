import { Dispatch, SetStateAction, useEffect } from 'react';
import { ISpace, useSpace } from '@flatfile/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { generateWorkbook } from './workbook';
import { createListener } from './listeners/FlatfileListener';

export const flatfileSpaceInfo: ISpace = {
  name: 'Embedded Space',
  publishableKey: 'pk_4b33df7d152e468391f965aa7c78314d',
  environmentId: 'us_env_3cYErbht',
};

export const FlatfileSpace = ({
  setShowFlatFileSpace,
  setSpaceId,
}: {
  setShowFlatFileSpace: Dispatch<SetStateAction<boolean>>;
  setSpaceId: Dispatch<SetStateAction<string | null>>;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const flags = useFlags();
  const workbook = generateWorkbook(flags);
  const listener = createListener(dispatch, navigate, flags);

  const space = useSpace({
    ...flatfileSpaceInfo,
    workbook,
    listener,
    sidebarConfig: {
      showSidebar: false
    },
    themeConfig: {
      root: {
        primaryColor: 'blue'
      },
      sidebar: {
        logo: 'https://images.ctfassets.net/hjneo4qi4goj/gL6Blz3kTPdZXWknuIDVx/7bb7c73d93b111ed542d2ed426b42fd5/flatfile.svg'
      }
    },
    closeSpace: {
      operation: 'submitActionFg',
      onClose: () => setShowFlatFileSpace(false)
    }
  });
  useEffect(() => {
    if (space?.props?.spaceId) {
      setSpaceId(space.props.spaceId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space?.props?.spaceId]);

  return space;
};