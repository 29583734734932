import React, { useEffect, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import SignIn from './components/Authentication/logIn';
import BidFileManager from 'components/Bidding/BidFileManager';
import BidFileManagerNewFlatFile from 'components/Bidding/BidFileManagerNewFlatFile';
import NetworkManager from './components/NetworkManager';
import { store, persistor } from 'redux/store';
import { Provider, useSelector } from 'react-redux';
import PrivateRoute from './components/Authentication/privateRoute';
import { ToastContainer } from 'react-toastify';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import EnvConfig from './config/config';
import Layout from 'components/Layout/Layout';
import NetworkView from 'components/NetworkView/NetworkView';
import BidConfig from 'components/Bidding/EditBidConfigTable/EditBidConfigTable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LaunchDarklyUserConfig from 'config/LaunchDarklyUserConfig';
import API from 'utils/axios';
import { env } from './env';
import { AlertsProvider } from 'components/NetworkView/Alerts/AlertsContext';
import PageNotFound from 'components/PageNotFound';
import BidAnalysis from 'components/BidAnalysis/BidAnalysis';
import * as FS from '@fullstory/browser';
import theme from 'theme';

const subdomain = window.location.host.includes('localhost')
    ? env.REACT_APP_CONNECTION
    : window.location.host.split('.')[0];

const connection = () => {
    let hasConnectionString = window.location.search.includes('?conn=');

    if (hasConnectionString) {
        if (sessionStorage.getItem('ODConnection')) {
            return sessionStorage.getItem('ODConnection');
        } else {
            sessionStorage.setItem('ODConnection', window.location.search.split('=')[1]);
            return window.location.search.split('=')[1];
        }
    } else if (
        window.location.host.includes('localhost') ||
        window.location.host.includes('dev') ||
        window.location.host.includes('demo')
    ) {
        return 'optimaldynamics';
    } else {
        return window.location.host.split('.')[0];
    }
};

const Auth = (props) => {
    const connectionOption = connection();
    const { organization, errorMessages, children } = props;
    const URI = window.location.origin;

    if (!Object.keys(organization).length && !errorMessages.length) return null;

    if (organization.connection_type === 'organization') {
        return (
            <Auth0Provider
                domain={env.REACT_APP_DOMAIN}
                clientId={env.REACT_APP_CLIENT_ID}
                redirectUri={URI}
                organization={organization.id}
                connection={connectionOption}
                scope={env.REACT_APP_SCOPE}
                audience={env.REACT_APP_AUDIENCE}>
                {children}
            </Auth0Provider>
        );
    }

    return (
        <Auth0Provider
            domain={env.REACT_APP_DOMAIN}
            clientId={env.REACT_APP_CLIENT_ID}
            redirectUri={URI}
            connection={subdomain}
            scope={env.REACT_APP_SCOPE}
            audience={env.REACT_APP_AUDIENCE}>
            {children}
        </Auth0Provider>
    );
};

const WaitForLDIdentify = ({ children }) => {
    const ldauth = useSelector((state) => state.SignInReducer.launchDarklyUserIdentified);
    return ldauth !== false ? children : <></>;
};

const App = () => {
    const { bidding, odpt4185BidConfigUpdate, useFlatfileUpgrade } = useFlags();
    const [organization, setOrganization] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);

    FS.init({ orgId: '18AJTW' });

    useEffect(() => {
        const fetchAuthType = async () => {
            const response = await API.get(`organizations/auth-type/${subdomain}/`);
            return response;
        };

        fetchAuthType()
            .then((res) => setOrganization(res.data))
            .catch((err) => setErrorMessages(err.response.data.errors));
    }, []);

    return (
        <Auth organization={organization} errorMessages={errorMessages}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <div>
                                <Helmet>
                                    <meta charSet="utf-8" />
                                    <title>Plan</title>
                                </Helmet>
                                <LaunchDarklyUserConfig />
                                <Router>
                                    <AlertsProvider>
                                        <Routes>
                                            <Route
                                                path="/login"
                                                element={<SignIn errorMessages={errorMessages} />}
                                            />
                                            <Route
                                                element={
                                                    <Layout>
                                                        <WaitForLDIdentify />
                                                    </Layout>
                                                }>
                                                <Route
                                                    path="/"
                                                    element={
                                                        <PrivateRoute>
                                                            <NetworkManager />
                                                        </PrivateRoute>
                                                    }
                                                />
                                                <Route
                                                    path="/bid-analysis/:analysis_id"
                                                    element={
                                                        <PrivateRoute>
                                                            <BidAnalysis />
                                                        </PrivateRoute>
                                                    }
                                                />
                                                <Route
                                                    path="/bid-analysis/:analysis_id/config/:config_id"
                                                    element={
                                                        <PrivateRoute>
                                                            <BidAnalysis />
                                                        </PrivateRoute>
                                                    }
                                                />
                                                {bidding && (
                                                    <Route
                                                        path="/bidding"
                                                        element={
                                                            <PrivateRoute>
                                                                {
                                                                useFlatfileUpgrade ? <BidFileManagerNewFlatFile /> :
                                                                <BidFileManager />
                                                                }
                                                            </PrivateRoute>
                                                        }
                                                    />
                                                )}
                                                {bidding && odpt4185BidConfigUpdate && (
                                                    <Route
                                                        path="/bidding/:bid_config_id"
                                                        element={
                                                            <PrivateRoute>
                                                                <BidConfig />
                                                            </PrivateRoute>
                                                        }
                                                    />
                                                )}
                                                <Route
                                                    path="/reports/report-run/:base_run/:compare_run?/:filtertype/:subfilter/:expanded?/:sidebar?"
                                                    element={
                                                        <PrivateRoute>
                                                            <NetworkView />
                                                        </PrivateRoute>
                                                    }
                                                />
                                                <Route
                                                    path="/reports/report-run/:base_run/:compare_run?/config/:config_id/:filtertype/:subfilter/:expanded?/:sidebar?"
                                                    element={
                                                        <PrivateRoute>
                                                            <NetworkView />
                                                        </PrivateRoute>
                                                    }
                                                />
                                                <Route
                                                    path="/reports/bucket/:bucket_id/:base_run/:compare_run?/:filtertype/:subfilter/:expanded?/:sidebar?"
                                                    element={
                                                        <PrivateRoute>
                                                            <NetworkView />
                                                        </PrivateRoute>
                                                    }
                                                />
                                                <Route
                                                    path="/reports/bucket/:bucket_id/:base_run/:compare_run?/config/:config_id/:filtertype/:subfilter/:expanded?/:sidebar?"
                                                    element={
                                                        <PrivateRoute>
                                                            <NetworkView />
                                                        </PrivateRoute>
                                                    }
                                                />
                                                <Route
                                                    path="/404"
                                                    element={
                                                        <PrivateRoute>
                                                            <PageNotFound />
                                                        </PrivateRoute>
                                                    }
                                                />
                                            </Route>
                                            <Route path="*" element={<Navigate to="/404" />} />
                                        </Routes>
                                    </AlertsProvider>
                                </Router>
                            </div>
                            <ToastContainer />
                        </PersistGate>
                    </Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Auth>
    );
};

export default withLDProvider({
    clientSideID: EnvConfig.getConfig().clientSideId,
    user: {
        key: EnvConfig.getConfig().sdkKey,
        custom: { organization: subdomain }
    }
})(App);
