/* eslint-disable react-hooks/exhaustive-deps */
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Box } from '@mui/material';
import * as FS from '@fullstory/browser';

import { ODButton, ODSelect, ODTextField, ODToggleButtonGroup } from 'shared';
import { createBidConfiguration, updateBiddingState } from 'redux/actions';
import palette from 'theme/palette';
import { BUTTON_TYPE } from 'constants/colors';
import { BUSINESS_TYPE } from 'constants/bidding';
import { toggleButtonsInfoRate } from 'constants/settings';
import Flatfile from 'components/FlatfileImporter/Flatfile';
import { launchFlatfile } from 'utils/flatfile.util';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState } from 'types/redux';
import { useNavigate } from 'react-router-dom';
import { updateFlatfileSpaceMetadata } from './FlatfileNewVersion/utils';


type Nullable<T> = T | null;
interface bidFileDetailsProps {
    handleClose: () => void;
    bidShippers: any;
    bidConfigId: any;
    spaceId?: any;
    setBidConfigId: (arg0: boolean) => void;
    resetFlatFileInfo: () => void;
    setShowFlatFileSpace: (arg0: boolean) => void;
}
interface onSubmitProps extends HTMLAttributes<HTMLElement> {
    newShipperID?: string;
    existingShipperID?: Nullable<string>;
    selectBusinessType: string;
    shipperProfile?: Nullable<string>;
    fileName: string;
    defaultRateType: Nullable<string>;
}

const validationSchema = yup.object({
    fileName: yup
        .string()
        .required('Required')
        .matches(
            /^[^\s]+(\s+[^\s]+)*$/,
            'This field cannot contain white space in the beginning or at the end of the string'
        )
        .nullable(),
    selectBusinessType: yup.string().required('Required').nullable(),
    shipperProfile: yup.string().when('selectBusinessType', {
        is: (value: string) => value === BUSINESS_TYPE.NEW,
        then: yup.string().required('Required'),
        otherwise: yup.string().nullable()
    }),
    newShipperID: yup.string().when('selectBusinessType', {
        is: (value: string) => {
            return value === BUSINESS_TYPE.NEW;
        },
        then: yup
            .string()
            .matches(
                /^[^\s]+(\s+[^\s]+)*$/,
                'This field cannot contain white space in the beginning or at the end of the string'
            )
            .required('Required')
            .nullable(),
        otherwise: yup.string().nullable()
    }),
    existingShipperID: yup.string().when('selectBusinessType', {
        is: (value: string) => value === BUSINESS_TYPE.EXISTING,
        then: yup.string().required('Required'),
        otherwise: yup.string().nullable()
    }),
    defaultRateType: yup.string().nullable()
});

const BidFileDetailsForm = ({
    handleClose,
    bidShippers,
    bidConfigId,
    setBidConfigId,
    spaceId,
    resetFlatFileInfo,
    setShowFlatFileSpace
}: bidFileDetailsProps) => {
    const dispatch = useDispatch();
    const { flatRates, odpt4185BidConfigUpdate, useFlatfileUpgrade } = useFlags();
    const navigate = useNavigate();
    const [importer, setImporter] = useState<any>(null);
    const { defaultRateType } = useSelector((state: RootState) => state.BiddingReducer);
    const initialFormState = {
        selectBusinessType: '',
        shipperProfile: '',
        newShipperID: '',
        existingShipperID: '',
        fileName: '',
        defaultRateType: null
    };

    const onSubmit = async (configData: onSubmitProps) => {
        await createBidConfiguration(configData).then((res) => {
            let currConfigId = res?.data?.data?.id;
            if (currConfigId) {
                if (useFlatfileUpgrade) {
                    setShowFlatFileSpace(true);
                    setBidConfigId(currConfigId);
                } else {
                    launchFlatfile(
                        dispatch,
                        importer,
                        currConfigId,
                        undefined,
                        navigate,
                        odpt4185BidConfigUpdate
                    );
                }
                FS.event('bid_file_manager_bidupload_flatfile_triggered_succesfully', {});
            }
        });
    };
    const isFileRateTypeSelected = flatRates ? defaultRateType !== null : true;
    const handleRateTypeUpdate = (value: string) => {
        dispatch(
            updateBiddingState({
                defaultRateType: value
            })
        );
    };
    useEffect(() => {
        resetFlatFileInfo();
    }, []);

    useEffect(() => {
        if(useFlatfileUpgrade && spaceId && bidConfigId) {
            const dataToSend = { currConfigId: bidConfigId };
            updateFlatfileSpaceMetadata(spaceId, dataToSend);
            return () => {};
        }
    }, [spaceId, bidConfigId]);

    return (
        <Formik
            initialValues={initialFormState}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                values.defaultRateType = defaultRateType;
                onSubmit(values);
                resetForm();
                handleClose();
                setSubmitting(false);
                resetFlatFileInfo();
            }}>
            {({ errors, handleSubmit, values, handleChange, dirty, isValid, resetForm }: any) => {
                const clearAndClose = () => {
                    resetForm();
                    handleClose();
                };

                const filteredBidShippers = (groupType: string) => {
                    let shippers: { value: string; name: string }[] | [] | undefined | null;

                    if (groupType === BUSINESS_TYPE.NEW) {
                        shippers = bidShippers?.reference_shippers?.map((item: string) => {
                            return { value: item, name: item };
                        });
                    } else if (groupType === BUSINESS_TYPE.EXISTING) {
                        shippers = bidShippers?.shippers?.map((item: string) => {
                            return { value: item, name: item };
                        });
                    } else {
                        shippers = [];
                    }

                    return shippers;
                };

                return (
                    <form onSubmit={handleSubmit}>
                        {flatRates ? (
                            defaultRateType && (
                                <Flatfile
                                    setImporter={setImporter}
                                    defaultRateType={defaultRateType}
                                />
                            )
                        ) : (
                            <Flatfile setImporter={setImporter} defaultRateType={defaultRateType} />
                        )}
                        <ODTextField
                            placeholder="Enter Name"
                            dataTestId="enter-file-name"
                            label="Enter Bid File Name"
                            value={values.fileName}
                            onChange={handleChange}
                            name="fileName"
                        />
                        <Box sx={{ fontSize: '12px', color: palette.semantic.semanticRed }}>
                            {errors?.fileName}
                        </Box>

                        <ODSelect
                            sx={{ marginTop: '16px' }}
                            dataTestId="business-type"
                            title="Select New or Existing Business"
                            name="selectBusinessType"
                            displayEmpty
                            value={values.selectBusinessType}
                            onChange={handleChange}
                            placeholder="Select Option"
                            itemList={[
                                { value: BUSINESS_TYPE.NEW, name: BUSINESS_TYPE.NEW },
                                { value: BUSINESS_TYPE.EXISTING, name: BUSINESS_TYPE.EXISTING }
                            ]}
                        />

                        {values?.selectBusinessType === BUSINESS_TYPE.NEW ? (
                            <>
                                <Box sx={{ marginTop: '16px' }}>
                                    <ODSelect
                                        dataTestId="select-shipper-profile"
                                        title="Select Shipper Preference"
                                        name="shipperProfile"
                                        displayEmpty
                                        placeholder="Select Option"
                                        value={values.shipperProfile}
                                        onChange={handleChange}
                                        itemList={
                                            filteredBidShippers(values.selectBusinessType) as any[]
                                        }
                                    />
                                </Box>
                                <Box sx={{ marginTop: '16px' }}>
                                    <ODTextField
                                        placeholder="Enter Name"
                                        dataTestId="enter-new-shipper-id"
                                        label="Enter New Shipper ID Name"
                                        value={values.newShipperID}
                                        onChange={handleChange}
                                        name="newShipperID"
                                    />
                                    <Box
                                        sx={{
                                            fontSize: '12px',
                                            color: palette.semantic.semanticRed
                                        }}>
                                        {errors?.newShipperID}
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <Box sx={{ marginTop: '16px' }}>
                                <ODSelect
                                    dataTestId="select-shipper-id"
                                    title="Select Shipper ID"
                                    name="existingShipperID"
                                    displayEmpty
                                    placeholder="Select Option"
                                    value={values.existingShipperID}
                                    onChange={handleChange}
                                    itemList={
                                        filteredBidShippers(values.selectBusinessType) as any[]
                                    }
                                />
                            </Box>
                        )}
                        {flatRates && (
                            <Box sx={{ marginTop: '16px' }}>
                                <Box
                                    sx={{
                                        fontSize: '12px',
                                        color: palette.neutral.neutral6,
                                        marginBottom: '8px'
                                    }}>
                                    Select Rate Type
                                </Box>

                                <ODToggleButtonGroup
                                    toggleButtons={toggleButtonsInfoRate}
                                    value={defaultRateType}
                                    handleAction={handleRateTypeUpdate}></ODToggleButtonGroup>
                            </Box>
                        )}
                        <Box
                            sx={{
                                marginTop: '32px',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <ODButton
                                buttonType={BUTTON_TYPE.BLUE}
                                type="submit"
                                width="188px"
                                buttonText="Confirm"
                                disabled={!(isValid && dirty && isFileRateTypeSelected)}
                            />
                            <ODButton buttonText="Cancel" width="188px" onClick={clearAndClose} />
                        </Box>
                    </form>
                );
            }}
        </Formik>
    );
};

export default BidFileDetailsForm;
